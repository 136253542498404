<p-dialog
  header="Deleting confirmation"
  [(visible)]="display"
  [modal]="true"
  [responsive]="true"
  [maximizable]="false"
  [baseZIndex]="10000"
  [closable]="false"
  styleClass="bl-modal"
  [positionTop]="65"
  [positionLeft]="390"
  *ngIf="selectedForDelete !== undefined"
>
  <p>
    You're deleting analysis:
    <strong
      >Loan number {{ selectedForDelete.operationNumber }}, audit period
      {{ selectedForDelete.auditDateRange }}.</strong
    >
    Are you sure that you want to proceed?
  </p>
  <p-footer>
    <button
      type="button"
      pButton
      (click)="selectedForDelete = undefined; display = false"
      label="Cancel"
    ></button>
    <button
      type="button"
      pButton
      (click)="removeAnalysis()"
      label="Continue"
      class="ui-button-secondary"
    ></button>
  </p-footer>
</p-dialog>
<div class="bl-historical-search t-padding-left-0 t-margin-bottom-4">
  <span class="bl-historical-search__title">Search Results</span>
</div>
<div class="bl-table">
  <p-table *ngIf="bodyTable && bodyTable.length > 0" [columns]="headerTable" [value]="bodyTable">
    <ng-template pTemplate="header" let-columns>
      <tr>
        <th
          class="historical-search-size"
          *ngFor="let col of columns"
          [pSortableColumn]="col.field"
          [pSortableColumnDisabled]="
            col.field === 'operationNumber' ||
            col.field === 'auditDateRange' ||
            col.field === 'projectName' ||
            col.field === 'afs' ||
            col.field === 'summaryReport' ||
            col.field === 'view'
          "
          [ngStyle]="{ width: col.width }"
        >
          {{ col.header }}
          <p-sortIcon
            *ngIf="
              col.field === 'analysisId' ||
              col.field === 'country' ||
              col.field === 'reviewDate' ||
              col.field === 'user'
            "
            [field]="col.field"
            ariaLabel="Activate to sort"
            ariaLabelDesc="Activate to sort in descending order"
            ariaLabelAsc="Activate to sort in ascending order"
          >
          </p-sortIcon>
        </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowData let-columns="columns">
      <tr>
        <td *ngFor="let col of columns">
          <div [ngSwitch]="col.field">
            <!-- <div *ngSwitchCase="'analysisId'" class="center-text historical-color">  {{ rowData[col.field] }}</div> -->
            <div *ngSwitchCase="'country'" class="historical-color">
              {{ rowData[col.field] }}
            </div>
            <div *ngSwitchCase="'operationNumber'" class="historical-color">
              {{ rowData[col.field] }}
            </div>
            <div *ngSwitchCase="'auditDateRange'" class="historical-color">
              {{ rowData[col.field] }}
            </div>
            <div *ngSwitchCase="'projectName'" class="historical-color">
              {{ rowData[col.field] }}
            </div>
            <div *ngSwitchCase="'user'" class="historical-color">
              {{ rowData[col.field] }}
            </div>
            <div *ngSwitchCase="'reviewDate'" class="historical-color">
              {{ rowData[col.field] }}
            </div>
            <div *ngSwitchCase="'summaryReport'">
              <a (click)="downloadDocs(rowData[col.field].path, rowData[col.field].name, 'summary')" class="link">{{
                rowData[col.field].name
              }}</a>
            </div>
            <div *ngSwitchCase="'afs'" class="historical-color">
              <p>AFS:</p>
              <a (click)="downloadDocs(rowData['afs'].path, rowData['afs'].name, 'afs')" class="link">{{
                rowData['afs'].name
              }}</a>
             
              <ng-container *ngIf="rowData['icr'].path"> 
                <p>ICR:</p>
                <a (click)="downloadDocs(rowData['icr'].path, rowData['icr'].name, 'icr')" class="link">{{
                  rowData['icr'].name
                }}</a>
              </ng-container>
            </div>
            <div *ngSwitchCase="'delete'" class="right-text historical-color">
              <span
                (click)="showConfirmationRemoveAnalysis(rowData)"
                *ngIf="col.field === 'delete' && rowData[col.field] != ''"
                [innerHTML]="rowData[col.field]"
              ></span>
            </div>
            <div *ngSwitchCase="'view'" class="right-text historical-color">
              <span
                (click)="redirectToAfsReview(rowData)"
                *ngIf="col.field === 'view'"
                [innerHTML]="rowData[col.field]"
              ></span>
            </div>
          </div>
        </td>
      </tr>
    </ng-template>
  </p-table>
</div>
