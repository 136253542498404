import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { NgModule, APP_INITIALIZER } from "@angular/core";
import { PublicClientApplication, InteractionType, BrowserCacheLocation, IPublicClientApplication } from '@azure/msal-browser';
import { environment } from "@environments/environment";
import { MsalModule, MsalService, MSAL_INSTANCE, MsalGuardConfiguration, MsalRedirectComponent, MsalInterceptorConfiguration, MSAL_GUARD_CONFIG } from '@azure/msal-angular';
import { MsalInterceptor, MsalGuard } from '@azure/msal-angular';
import { AuthenticationService } from "./authentication.service";
import { TokenInterceptor } from "./token.interceptor";

const baseUrl = environment.api.base;
const baseUploadUrl = environment.api.baseUpload;
const scope = environment.api.scope;

const isIE =
  window.navigator.userAgent.indexOf("MSIE ") > -1 ||
  window.navigator.userAgent.indexOf("Trident/") > -1;

  export function MSALInstanceFactory(): IPublicClientApplication {
    return new PublicClientApplication({
      auth: {
        clientId: environment.auth.clientId, 
        authority: environment.auth.authority,
        //validateAuthority: true,
        redirectUri: environment.auth.redirectUri,
        postLogoutRedirectUri: environment.auth.postLogoutRedirectUri,
        navigateToLoginRequestUrl: true,
      },
      cache: {
        cacheLocation: BrowserCacheLocation.LocalStorage,
        storeAuthStateInCookie: isIE, // set to true for IE 11
      },
    });
  }
  
  export function MSALGuardConfigFactory(): MsalGuardConfiguration {
    return {
      interactionType: InteractionType.Redirect,  
      authRequest: {
        scopes: ['user.read'] 
      }
    };
  }

  export function initializeAppFactory(msalService: MsalService): () => Promise<void> {
    return () => msalService.instance.initialize();
  }
  
  export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
    return {
      interactionType: InteractionType.Redirect,
      protectedResourceMap: new Map([
        ['https://graph.microsoft.com/v1.0/me', ['user.read']],
        [`${baseUrl}utils/ocr`,  [`${scope}`],],
        [`${baseUrl}utils/requirements`, [`${scope}`],],
        [`${baseUrl}utils/criteria`,[`${scope}`],],
        [`${baseUrl}utils/data`,
          [`${scope}`],
        ],
        [`${baseUrl}users/authorization`,
          [`${scope}`],
        ],
        [`${baseUrl}users/*`,
          [`${scope}`],
        ],
        [`${baseUrl}users/user/*`,
          [`${scope}`],
        ],
        [`${baseUrl}users/user/*/check`,
          [`${scope}`],
        ],
        [
          `${baseUrl}trainings/date`,
          [`${scope}`],
        ],
        [
          `${baseUrl}trainings/*/findings`,
          [`${scope}`],
        ],
        [
          `${baseUrl}trainings/*/opinions`,
          [`${scope}`],
        ],
        [
          `${baseUrl}trainings/date`,
          [`${scope}`],
        ],
        [
          `${baseUrl}trainings/dates`,
          [`${scope}`],
        ],
        [
          `${baseUrl}trainings/models`,
          [`${scope}`],
        ],
        [
          `${baseUrl}tests/parameters/*`,
          [`${scope}`],
        ],
        [
          `${baseUrl}tests`,
          [`${scope}`],
        ],
        [
          `${baseUrl}tests/suggested`,
          [`${scope}`],
        ],
        [
          `${baseUrl}tests/details`,
          [`${scope}`],
        ],
        [
          `${baseUrl}tests/dictionary/*/test`,
          [`${scope}`],
        ],
        [
          `${baseUrl}tests/dictionary/*/icr`,
          [`${scope}`],
        ],
        [
          `${baseUrl}summary-reports/*`,
          [`${scope}`],
        ],
        [
          `${baseUrl}summary-reports/*/draft`,
          [`${scope}`],
        ],
        [
          `${baseUrl}scores/ranges`,
          [`${scope}`],
        ],
        [
          `${baseUrl}scores/*`,
          [`${scope}`],
        ],
        [
          `${baseUrl}icrs`,
          [`${scope}`],
        ],
        [
          `${baseUrl}icrs/observations`,
          [`${scope}`],
        ],
        [
          `${baseUrl}icrs/*/observations`,
          [`${scope}`],
        ],
        [
          `${baseUrl}icrs/observations/*`,
          [`${scope}`],
        ],
        [
          `${baseUrl}icrs/observations/list`,
          [`${scope}`],
        ],
        [
          `${baseUrl}icrs/*/informations`,
          [`${scope}`],
        ],
        [
          `${baseUrl}icrs/*/complete`,
          [`${scope}`],
        ],
        [
          `${baseUrl}efas`,
          [`${scope}`],
        ],
        [
          `${baseUrl}efas/*`,
          [`${scope}`],
        ],
        [
          `${baseUrl}efas/*/save-time`,
          [`${scope}`],
        ],
        [
          `${baseUrl}efas/*/complete`,
          [`${scope}`],
        ],
        [
          `${baseUrl}efas/*/information`,
          [`${scope}`],
        ],
        [
          `${baseUrl}efas/history`,
          [`${scope}`],
        ],
        [
          `${baseUrl}convergences/lms`,
          [`${scope}`],
        ],
        [
          `${baseUrl}convergences/ezshare`,
          [`${scope}`],
        ],
        [
          `${baseUrl}convergences/users`,
          [`${scope}`],
        ],
        [
          `${baseUrl}dictionarys/keywords`,
          [`${scope}`],
        ],
        [
          `${baseUrl}dictionarys/keywords/*`,
          [`${scope}`],
        ],
        [
          `${baseUploadUrl}*`,
          [`${scope}`],
        ],
        [
          `${baseUploadUrl}documents**`,
          [`${scope}`],
        ],
        [
          `${baseUploadUrl}documents/**`,
          [`${scope}`],
        ],
        [
          `${baseUploadUrl}documents*`,
          [`${scope}`],
        ],
        [
          `${baseUploadUrl}documents/*`,
          [`${scope}`],
        ],
        [
          `${baseUploadUrl}documents[*]`,
          [`${scope}`],
        ],
      ])
    };
  }


@NgModule({
  imports: [
    MsalModule.forRoot(
      MSALInstanceFactory(),
      MSALGuardConfigFactory(),
      MSALInterceptorConfigFactory()
    )

  ],
  providers: [
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory
    },
    MsalService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeAppFactory,
      deps: [MsalService],
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
    MsalGuard,
    AuthenticationService,
  ],
  bootstrap: [MsalRedirectComponent],
  exports: [MsalModule],
})
export class AuthenticationModule {}
