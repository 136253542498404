<p-dialog
  header="Choose document"
  *ngIf="modalEzshare"
  [(visible)]="modalEzshare"
  [modal]="true"
  [responsive]="true"
  [maximizable]="false"
  [baseZIndex]="10000"
  position="center"
  [closable]="false"
  styleClass="bl-modal bl-modal--big"
> 
  <div
    class="t-text-align-left t-display-flex t-padding-bottom-20"
    style="align-items: center; justify-content: space-between"
  >
    <p>Select one document for review.</p>
    <div class="bl-input">
      <label class="bl-input__label">Search AFS</label>
      <input
        type="text"
        pInputText
        placeholder=""
        
        [disabled]="!bodyTable?.length"
      />
    </div> <!-- (input)="dt.filterGlobal($event.target.value, 'contains')"-->
  </div>
  <div class="bl-table">
    <p-table
      #dt
      [columns]="headerTable"
      [value]="bodyTable"
      [responsive]="true"
      (sortFunction)="customSort($event)"
      [customSort]="true"
      [scrollable]="true" 
      scrollDirection="vertical" scrollHeight="300px"
    >
      <ng-template pTemplate="header" let-columns>
        <tr>
          <th
            [ngClass]="{
              sortable: col.field !== 'radio'
            }"
            *ngFor="let col of columns"
            [pSortableColumn]="col.field"
            [pSortableColumnDisabled]="col.field === 'radio'"
            [ngStyle]="{ width: col.width }"
          >
            <span> {{ col.header }}</span>
            <!--p-sortIcon
              *ngIf="col.field !== 'radio'"
              [field]="col.field"
              ariaLabel="Activate to sort"
              ariaLabelDesc="Activate to sort in descending order"
              ariaLabelAsc="Activate to sort in ascending order"
            >
            </p-sortIcon-->
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-rowData let-columns="columns">
        <tr>
          <td *ngFor="let col of columns; index as i">
            <div [ngSwitch]="col.field">
              <div *ngSwitchCase="'radio'">
                <p-radioButton
                  (onClick)="selectedDocument = rowData"
                  class="bl-generate-analysis__upload-radio"
                  name="group-{{ i }}"
                ></p-radioButton>
              </div>
              <div *ngSwitchDefault>
                {{ rowData[col.field] }}
              </div>
            </div>
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage" let-columns>
        <ng-container *ngIf="loader">
          <tr>
            <td [attr.colspan]="columns.length" class="t-text-align-center">Searching files...</td>
          </tr>
        </ng-container>
        <ng-container *ngIf="!loader">
          <tr>
            <td [attr.colspan]="columns.length" class="t-text-align-center">No files found</td>
          </tr>
        </ng-container>
      </ng-template>
    </p-table>
  </div>
  <p-footer>
    <button type="button" pButton (click)="modalEzshare = false" label="Cancel"></button>
    <button
      type="button"
      pButton
      (click)="selectFile(selectedDocument)"
      label="Add file"
      class="ui-button-secondary"
    ></button>
  </p-footer>
</p-dialog>
<p-toast
  class="bl-toast bl-toast--success"
  key="successMessageEzshareTable"
  closable="true"
  [escape]="true"
>
  <ng-template let-message pTemplate="message">
    <span class="ui-toast-icon template-position">
      <i class="fas fa-check"></i>
    </span>
    <div class="ui-toast-message-text-content">
      <h3 class="ui-toast-summary">Success</h3>
      <span class="ui-toast-detail"
        >Analysis completed.
        <!-- <a class="link-style">{{ templateSuccess.scoreTemplate }}</a> -->
        to see the results</span
      >
    </div>
  </ng-template>
</p-toast>

<p-toast class="bl-toast bl-toast--error" key="errorMessageEzshareTable" closable="true"></p-toast>
<div class="">
  <div class="ui-fileupload-buttonbar">
    <p-button
      class="ui-fileupload-choose ui-button"
      (click)="chooseFile()"
      [disabled]="!loanNumber?.length"
    >
      Choose file in ezShare 1
    </p-button>
  </div>
</div>
<a (click)="backToTop()" class="bl-anchor" style="bottom: 0.5em;">
  <div class="bl-anchor__arrow"></div>
  <div class="bl-anchor__square">Back to top</div>
</a>
