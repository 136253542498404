<p-toast
  class="bl-toast bl-toast--success"
  key="successMessageAfsReview"
  closable="true"
  [escape]="true"
>
  <ng-template let-message pTemplate="message">
    <span class="ui-toast-icon template-position">
      <i class="fas fa-check"></i>
    </span>
    <div class="ui-toast-message-text-content">
      <h3 class="ui-toast-summary">Success</h3>
      <span class="ui-toast-detail"
        >Analysis completed.
        <a (click)="save('calculateScore')" class="link-style">{{
          templateSuccess.scoreTemplate
        }}</a>
        to see the results</span
      >
    </div>
  </ng-template>
</p-toast>

<p-toast
  class="bl-toast bl-toast--error"
  key="errorMessageAfsReviewTable"
  closable="true"
></p-toast>
<div class="bl-table-legend col-12" *ngIf="tableButtons">
  <div class="bl-table-legend__type-of-test-wrapper col-lg-8 col-md-8 col-sm-6 col-xs-6">
    <div class="bl-table-legend__score-title">
      <span>Type of test</span>
    </div>
    <ul class="bl-table-legend__list">
      <li class="bl-table-legend__list--blue-bullet bl-table-legend__list--element-list-margin">
        Initial analysis
      </li>
      <li
        class="bl-table-legend__list--two-color-bullet bl-table-legend__list--element-list-margin"
      >
        Initial & Basic Elements of the Auditor 's Opinion
      </li>
    </ul>
    <ul class="bl-table-legend__list">
      <li class="bl-table-legend__list--brown-bullet bl-table-legend__list--element-list-margin">
        A: Basic Elements of the Auditor`s Opinion
      </li>
      <li class="bl-table-legend__list--green-bullet bl-table-legend__list--element-list-margin">
        B: Project Financial Statements
      </li>
      <li class="bl-table-legend__list--yellow-bullet bl-table-legend__list--element-list-margin">
        C: Notes to Financial Statements
      </li>
    </ul>
  </div>
  <div class="col-lg-4 col-md-4 col-sm-6 col-xs-6 bl-table-legend__buttons-wrapper align-end">
    <div class="bl-table-legend__content-button">
      <!--input[name=""] -->
      <button (click)="exportToPdf()" class="bl-button--btn-seacher bl-button--btn-seacher--lg">
        <i class="far fa-file-pdf"></i> Export to pdf
      </button>
      <button
        (click)="save('saveTest')"
        [disabled]="!myFormGroup.touched"
        class="bl-button--btn-save"
        type="submit"
      >
        Save
      </button>
      <button
        (click)="save('calculateScore')"
        [disabled]="disabledButton"
        class="bl-button--btn-secundary bl-button--btn-secundary--lg"
        type="submit"
      >
        Calculate Score
      </button>
    </div>
  </div>
</div>
<form [formGroup]="myFormGroup">
  <fieldset>
    <legend class="hide-accessible">Form of test</legend>
    <div class="bl-table">
      <p-table [columns]="headerTable" [value]="bodyTable" [responsive]="true" sortMode="multiple">
        <ng-template pTemplate="header" let-columns>
          <tr>
            <th
              [ngClass]="{
                sortable:
                  col.field === 'type' ||
                  col.field === 'test_id' ||
                  col.field === 'name' ||
                  col.field === 'answer_luca' ||
                  col.field === 'answerFms'
              }"
              *ngFor="let col of columns"
              [pSortableColumn]="col.field"
              [pSortableColumnDisabled]="
                col.field === 'parameter_luca' ||
                col.field === 'parameterFms' ||
                col.field === 'notes'
              "
              [ngStyle]="{ width: col.width }"
            >
              <span *ngIf="col.field !== 'suggestedParameters'"> {{ col.header }}</span>
              <span *ngIf="col.field === 'suggestedParameters'">
                {{ col.header }}
                <a
                  class="bl-input__tooltip bl-tooltip__down"
                  pTooltip="{{ tooltipInfoAFSReview }}"
                  tooltipPosition="top"
                  [escape]="false"
                >
                  <i class="fas fa-info-circle"></i>
                </a>
              </span>
              <p-sortIcon
                *ngIf="
                  col.field === 'type' ||
                  col.field === 'test_id' ||
                  col.field === 'name' ||
                  col.field === 'answer_luca' ||
                  col.field === 'answerFms'
                "
                [field]="col.field"
                ariaLabel="Activate to sort"
                ariaLabelDesc="Activate to sort in descending order"
                ariaLabelAsc="Activate to sort in ascending order"
              >
              </p-sortIcon>
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData let-columns="columns" let-i="rowIndex">
          <tr>
            <td
              *ngFor="let col of columns"
              [ngClass]="{
                'bl-select--has-error':
                  subtmitedForm === true &&
                  !rowData['answerFms'] &&
                  rowData.answer_luca === 'Manual'
              }"
            >
              <div
                [ngSwitch]="col.field"
                [ngClass]="{
                  colorRed:
                    rowData.answerFms !== 'Yes' &&
                    rowData.answer_luca === 'No' &&
                    scoreScreen === true,
                  colorGrey: rowData.answer_luca === 'Manual'
                }"
              >
                <div *ngSwitchCase="'type'">
                  <a
                    title="Initial analysis"
                    *ngIf="
                      rowData[col.field] === 'Init' ||
                      (rowData[col.field] === 'Init_pass' && scoreScreen === false) ||
                      rowData[col.field] === 'Init' ||
                      (rowData[col.field] === 'Init_pass' &&
                        !(
                          rowData.answerFms !== 'Yes' &&
                          rowData.answer_luca === 'No' &&
                          scoreScreen === true
                        ))
                    "
                    ><i class="bullet-blue"></i
                  ></a>

                  <a
                    title="Initial & Basic Elements of the Auditor 's Opinion"
                    *ngIf="
                      (rowData[col.field] === 'A_pass' && scoreScreen === false) ||
                      (rowData[col.field] === 'A_pass' &&
                        !(
                          rowData.answerFms !== 'Yes' &&
                          rowData.answer_luca === 'No' &&
                          scoreScreen === true
                        ))
                    "
                  >
                    <i class="two-color-bullet"></i
                  ></a>
                  <a
                    title="A: Basic Elements of the Auditor`s Opinion"
                    *ngIf="
                      rowData[col.field] === 'A' &&
                      !(rowData.answerFms === 'No' && scoreScreen === true)
                    "
                    ><i class="bullet-brown"></i
                  ></a>
                  <a
                    title="B: Project Financial Statements"
                    *ngIf="
                      rowData[col.field] === 'B' &&
                      !(rowData.answerFms === 'No' && scoreScreen === true)
                    "
                    ><i class="bullet-green"></i
                  ></a>
                  <a
                    title="C: Notes to Financial Statements"
                    *ngIf="
                      rowData[col.field] === 'C' &&
                      !(rowData.answerFms === 'No' && scoreScreen === true)
                    "
                    ><i class="bullet-yellow"></i
                  ></a>
                  <a
                    title="warning"
                    *ngIf="
                      rowData.answerFms !== 'Yes' &&
                      rowData.answer_luca === 'No' &&
                      scoreScreen === true
                    "
                    ><i class="fas fa-exclamation-triangle bullet-warning"></i>
                  </a>
                </div>
                <div *ngSwitchCase="'test_id'">
                  {{ rowData[col.field] }}
                </div>
                <div *ngSwitchCase="'name'">
                  <a *ngIf="bodyTable[i].description" class="tooltip top"
                    >{{ rowData[col.field] }}
                    <span class="tiptext">{{ bodyTable[i].description }}</span>
                  </a>
                  <span *ngIf="!bodyTable[i].description">{{ rowData[col.field] }}</span>
                </div>
                <div *ngSwitchCase="'answer_luca'">
                  <span *ngIf="rowData[col.field] === 'Manual'"
                    ><i class="colorRed">*</i> Manual</span
                  >
                  <span *ngIf="rowData[col.field] === 'No' || rowData[col.field] === 'Yes'">{{
                    rowData[col.field]
                  }}</span>
                </div>
                <div *ngSwitchCase="'parameter_luca'">
                  <div
                    *ngIf="rowData['test_id'] !== 9 && rowData['test_id'] !== 10"
                    class="scroll-table"
                  >
                    <label for="textareaParameterLuca-'+{{ i }}" class="hide-accessible"
                      >parameter_luca - {{ i }}</label
                    >
                    <span>{{ rowData[col.field] }}</span>
                  </div>
                  <div
                    *ngIf="rowData['test_id'] === 9 || rowData['test_id'] === 10"
                    class="scroll-table"
                  >
                    <label for="textareaParameterLuca-'+{{ i }}" class="hide-accessible"
                      >parameter_luca - {{ i }}</label
                    >
                    <span>{{ rowData[col.field] | date: 'yyyy/MM/dd' }}</span>
                  </div>
                </div>
                <div *ngSwitchCase="'answerFms'">
                  <div class="bl-select t-margin-top-0" *ngIf="editable; else answerFmsSpan">
                    <label for="selectanswerFms-'+{{ i }}" class="hide-accessible"
                      >answerFms - {{ i }}</label
                    >
                    <p-dropdown
                      *ngIf="rowData.answer_luca !== 'Manual'"
                      bid-disable-control
                      (onChange)="validation()"
                      [name]="'selectanswerFms-' + i"
                      [formControlName]="'selectanswerFms-' + i"
                      [options]="valueSelect"
                      [(ngModel)]="rowData[col.field]"
                      value="rowData[col.field]"
                      required="true"
                    ></p-dropdown>

                    <p-dropdown
                      *ngIf="rowData.answer_luca === 'Manual'"
                      bid-disable-control
                      (onChange)="validation()"
                      [name]="'selectanswerFms-' + i"
                      [formControlName]="'selectanswerFms-' + i"
                      [options]="valueSelectManual"
                      [(ngModel)]="rowData[col.field]"
                      value="rowData[col.field]"
                      required="true"
                    ></p-dropdown>
                  </div>
                  <ng-template #answerFmsSpan>
                    <span>{{ rowData[col.field] }}</span>
                  </ng-template>
                </div>
                <div *ngSwitchCase="'parameterFms'">
                  <!--ParameterFms-->
                  <div class="bl-textarea" *ngIf="editable; else noteSpan">
                    <ng-container
                      *ngIf="
                        !rowData['luca_database'] ||
                          (rowData['test_id'] !== 3 && rowData['test_id'] !== 4);
                        else lucaDatabase
                      "
                    >
                      <label for="textareasComments-'+{{ i }}" class="hide-accessible"
                        >notes - {{ i }}</label
                      >
                      <textarea
                        *ngIf="rowData['test_id'] !== 7"
                        [name]="'textareasComments-' + i"
                        [formControlName]="'textareasComments-' + i"
                        [(ngModel)]="rowData[col.field]"
                        class="bl-textarea__area--size-small bl-textarea__area--colorTable"
                        [ngClass]="{
                          colorRed:
                            rowData.answerFms === 'No' ||
                            (rowData.answer_luca === 'No' && scoreScreen === true)
                        }"
                        (onChange)="validation()"
                      ></textarea>
                      <div class="bl-select t-margin-top-0">
                        <p-dropdown
                            *ngIf="rowData['test_id'] == 7"
                              (onChange)="validation()"
                              [name]="'textareasComments-' + i"
                              [formControlName]="'textareasComments-' + i"
                              [options]="valueSelectComment"
                              [(ngModel)]="rowData[col.field]"
                              value="rowData[col.field]"
                              required="true"
                        ></p-dropdown>
                      </div>
                      <ng-template #dropdownComments>
                        <div class="bl-select t-margin-top-0">
                          <p-dropdown
                            bid-disable-control
                            (onChange)="validation()"
                            [name]="'textareasComments-' + i"
                            [formControlName]="'textareasComments-' + i"
                            [options]="valueSelectComment"
                            [(ngModel)]="rowData[col.field]"
                            value="rowData[col.field]"
                            required="true"
                          ></p-dropdown>
                        </div>
                      </ng-template>
                    </ng-container>
                    <ng-template #lucaDatabase>
                      <div class="bl-radiobutton">
                        <div class="bl-radiobutton__block-line t-display-flex t-padding-bottom-10">
                          <p-radioButton
                            class="t-padding-right-10"
                            name="radio-{{ rowData['test_id'] }}"
                            [value]="true"
                            [formControlName]="'radio-' + i"
                            (click)="
                              rowData[col.field] = '';
                              myFormGroup.controls['textareasComments-' + i].setValue('')
                            "
                          >
                          </p-radioButton>
                          <p>{{ rowData['luca_database'] }}</p>
                        </div>
                        <div class="bl-radiobutton__block-line t-display-flex">
                          <p-radioButton
                            class="t-padding-right-10"
                            name="radio-{{ rowData['test_id'] }}"
                            [value]="false"
                            [formControlName]="'radio-' + i"
                          >
                          </p-radioButton>
                          <textarea
                            *ngIf="rowData['test_id'] !== 7"
                            [name]="'textareasComments-' + i"
                            [formControlName]="'textareasComments-' + i"
                            [(ngModel)]="rowData[col.field]"
                            class="bl-textarea__area--size-small bl-textarea__area--colorTable"
                            [ngClass]="{
                              colorRed:
                                rowData.answerFms === 'No' ||
                                (rowData.answer_luca === 'No' && scoreScreen === true)
                            }"
                            (onChange)="validation()"
                          ></textarea>
                        </div>
                      </div>
                    </ng-template>
                  </div>
                  <ng-template #noteSpan>
                    <span
                      *ngIf="
                        rowData[col.field] &&
                        !(
                          rowData.answerFms === 'No' ||
                          (rowData.answer_luca === 'No' && scoreScreen === true)
                        )
                      "
                      >{{ rowData[col.field] }}</span
                    >
                    <span
                      *ngIf="
                        !rowData[col.field] &&
                        !(
                          rowData.answerFms === 'No' ||
                          (rowData.answer_luca === 'No' && scoreScreen === true)
                        )
                      "
                      >{{ rowData['luca_database'] }}</span
                    >
                  </ng-template>
                </div>
                <div *ngSwitchCase="'suggestedParameters'">
                  <div class="bl-textarea" *ngIf="editable; else parameterSpan">
                    <label for="textareaAuditionToFms-'+{{ i }}" class="hide-accessible"
                      >textareaAuditionToFms - {{ i }}</label
                    >
                    <textarea
                      [name]="'textareaAuditionToFms-' + i"
                      [formControlName]="'textareaAuditionToFms-' + i"
                      [(ngModel)]="rowData[col.field]"
                      class="bl-textarea__area--size-small bl-textarea__area--colorTable--disabled-field"
                      [ngClass]="{
                        colorRed:
                          rowData.answerFms !== 'Yes' &&
                          rowData.answer_luca === 'No' &&
                          scoreScreen === true
                      }"
                      (change)="validation()"
                      bidDisableControl="{{
                        rowData.answerFms === 'No' || rowData.show_additions === false
                      }}"
                      defaultColor="violet"
                    ></textarea>
                  </div>
                  <ng-template #parameterSpan>
                    <span>{{ rowData[col.field] }}</span>
                  </ng-template>
                </div>
              </div>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
    <div class="col-12 t-padding-right-0 t-padding-left-0" *ngIf="tableButtons">
      <div class="col-6"></div>
      <div class="col-6 t-padding-right-0">
        <div class="bl-table-legend__content-button">
          <button (click)="exportToPdf()" class="bl-button--btn-seacher bl-button--btn-seacher--lg">
            <i class="far fa-file-pdf"></i> Export to pdf
          </button>
          <button
            class="bl-button--btn-save"
            type="submit"
            (click)="save('saveTest')"
            [disabled]="disabledButton"
          >
            Save
          </button>
          <button
            class="bl-button--btn-secundary bl-button--btn-secundary--lg"
            type="submit"
            (click)="save('calculateScore')"
            [disabled]="disabledButton"
          >
            Calculate Score
          </button>
        </div>
      </div>
    </div>
  </fieldset>
</form>
<a *ngIf="!scoreScreen" (click)="backToTop()" class="bl-anchor" style="bottom: 0.5em;">
  <div class="bl-anchor__arrow"></div>
  <div class="bl-anchor__square">Back to top</div>
</a>
